import React from "react";
import { FormStep } from "@helpers/ApplicationFormHooks";
import ApplicationStartSuccessPage from "@components/ApplicationStartSuccessPage/ApplicationStartSuccessPage";
import {
	useInstitutionFormState,
	INSTITUTION_FORM_STEPS,
} from "./_useInstitutionFormState";
import SEO from "@components/PageLayout/SEO";
import { PageLayoutMetaData } from "@components/PageLayout/_PageLayoutMetaData";

const InstitutionStart: React.FunctionComponent = () => {
	const { navigateToStep } = useInstitutionFormState(
		INSTITUTION_FORM_STEPS.START.formStep
	);

	return (
		<>
			<SEO title={PageLayoutMetaData.institution.title[INSTITUTION_FORM_STEPS.START.formStep]} />
			<ApplicationStartSuccessPage
				image="institution_start"
				header="Apply as Institution"
				text="By submitting this form, you certify that your organization is a legally recognized non-profit organization and that you have also agreed to our"
				includePrivacyPolicy
				buttonText="Start"
				minutes={5}
				onClick={() =>
					navigateToStep(
						(INSTITUTION_FORM_STEPS.START.formStep + 1) as FormStep
					)
				}
			/>
		</>

	);
};

export default InstitutionStart;
